import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Reemplaza 'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', y 'YOUR_USER_ID' con tus propios valores
    emailjs.sendForm('service_9outl0b', 'template_5myk2ov', e.target, 'pjMxxSZqm9p-u9cHd')
      .then((response) => {
        console.log('Email sent successfully:', response);
        window.location.replace('')

        // Aquí puedes agregar lógica adicional después de enviar el formulario
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  };

  return (
    <section className='py-16 lg:section' id='contact'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row'>
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 flex justify-start items-center'
          >
            <div>
              <h4 className='text-xl uppercase text-accent font-medium mb-2 tracking-wide'>
                Hablemos
              </h4>
              <h2 className='text-[45px] lg:text-[90px] leading-none mb-12'>
                Trabajemos <br /> juntos!
              </h2>
            </div>
          </motion.div>

          <motion.form
            onSubmit={handleSubmit}
            variants={fadeIn('left', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 border rounded-2xl flex flex-col gap-y-6 pb-24 p-6 items-start'
          >
            <input
              name='name'
              value={formData.name}
              onChange={handleChange}
              className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all'
              type='text'
              placeholder='Tu nombre'
            />
            <input
              name='email'
              value={formData.email}
              onChange={handleChange}
              className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all'
              type='text'
              placeholder='Tu email'
            />
            <textarea
              name='message'
              value={formData.message}
              onChange={handleChange}
              className='bg-transparent border-b py-12 outline-none w-full placeholder:text-white focus:border-accent transition-all resize-none mb-12'
              placeholder='Tu mensaje'
            ></textarea>
            <button type='submit' className='btn btn-lg'>
              Enviar mensaje
            </button>
          </motion.form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
