import React from 'react';
// icon
import { BsArrowUpRight } from 'react-icons/bs';
// motion
import { motion } from 'framer-motion';
// variants
import { fadeIn } from '../variants';

// services data
const services = [
  {
    name: 'Naranja X - Software Engineer',
    description:
      'Desarrolle las soluciones estéticas y funcionales en nuestro proyecto.',
    link: 'May 2021 - May 2022',
  },
  {
    name: 'JP Morgan - Software Engineer',
    description:
      'A cargo de solucionar errores y fallos en nuestra aplicación de migración de DB.',
    link: 'Aug 2022 - Mar 2023',
  },
  {
    name: 'Inclumap - Founder',
    description:
      'Aplicación web y móvil para mejorar la accesibilidad de las personas con discapacidad.',

  },
  {
    name: 'Naranja X - Software Engineer',
    description:
      'Brindo soporte a diversas verticales en tecnologías como Node y AWS.',
    link: 'Actualidad',
  },
];

const Services = () => {
  return (
    <section className='section' id='services'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row'>
          {/* text & image */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 lg:bg-services lg:bg-bottom bg-no-repeat mix-blend-lighten mb-12 lg:mb-0'
          >
            <h2 className='h2 text-accent mb-6'>Lo que hago.</h2>
            <h3 className='h3 max-w-[455px] mb-16'>
              Soy un desarrollador de software con más de 3 años de experiencia corporativa.
            </h3>
            <a href='https://www.linkedin.com/in/joaquingiorgis/' target='_blank' className='text-gradient btn-link'>
              <button className='btn btn-sm'>Mira mi perfil</button>

            </a>
          </motion.div>
          {/* services */}
          <motion.div
            variants={fadeIn('left', 0.5)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1'
          >
            {/* service list */}
            <div>
              {services.map((service, index) => {
                // destructure service
                const { name, description, link } = service;
                return (
                  <div
                    className='border-b border-white/20 h-[146px] mb-[38px] flex'
                    key={index}
                  >
                    <div className='max-w-[476px]'>
                      <h4 className='text-[20px] tracking-wider font-primary font-semibold mb-6'>
                        {name}
                      </h4>
                      <p className='font-secondary leading-tight'>
                        {description}
                      </p>
                    </div>
                    <div className='flex flex-col flex-1 items-end'>
                      <a
                        href='https://www.linkedin.com/in/joaquingiorgis/'
                        target='_blank'
                        className='btn w-9 h-9 mb-[42px] flex justify-center items-center'
                      >
                        <BsArrowUpRight />
                      </a>
                      <a className='text-gradient text-sm'>
                        {link}
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Services;
