import React from 'react';
// images
import Logo from '../assets/logo-joaquin.png';

const Header = () => {
  return (
    <header className='py-8'>
      <div className='container mx-auto'>
        <div className='flex justify-between items-center'>
          {/* logo */}
          <a href='#'>
            <img src={Logo} alt='' />
          </a>
          {/* button */}

          <a href='https://www.linkedin.com/in/joaquingiorgis/' rel="noreferrer"  target='_blank' className='text-gradient btn-link'>
            <button className='btn btn-sm'>Contactame</button>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
